import { useFormik } from "formik";
import { User } from "lucide-react";
import React, { useState } from "react";

import LoginImage from "../../../../assets/Image/LoginImg.png";

import { ENUM_STATUS_CODE } from "src/helpers";

import {
  ForgotPasswordMobileNoInitialVal,
  ForgotPasswordMobileNoProps,
  validationSchemaForgotPasswordMobile,
} from "./modules";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios, { AxiosError } from "axios";
import {
  updateError,
  updateErrorCode,
} from "src/redux/Reducers/errorHadlingSlicer";
import {
  updateForgotData,
  updateForgotEmailId,
  updatePhoneNumber,
} from "src/redux/Reducers/loginSlicer";
import LInputComp from "src/components/commons/InputComponent/LInputComp";
import Buttons from "src/components/commons/Buttons/Buttons";
import { forgotDetailViaEmail } from "src/services/services";

const EnterMobileNo = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik<ForgotPasswordMobileNoProps>({
    initialValues: ForgotPasswordMobileNoInitialVal,
    onSubmit: async (values, { resetForm, setValues }) => {
      const postData = {
        ...values,
      };
      if (postData) {
        henadleSendOtpApi(postData);
      }
    },

    validationSchema: validationSchemaForgotPasswordMobile,
  });

  const henadleSendOtpApi = async (postData: any) => {
    setLoading(true);
    try {
      let apiResp = await forgotDetailViaEmail(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        navigate("otp-forgot");
        dispatch(updateForgotData(apiResp?.data));

        formik.resetForm();
        formik.setValues(ForgotPasswordMobileNoInitialVal);
        dispatch(updatePhoneNumber(apiResp?.data?.phone || ""));
      } else {
        dispatch(updateErrorCode(apiResp.code));
        dispatch(updateError(apiResp.message));
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateErrorCode(error.response?.data?.code));
        dispatch(
          updateError("An unexpected error occurred. Please try again later.")
        );
      } else {
        dispatch(
          updateError("An unexpected error occurred. Please try again later.")
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full p-4 h-[100%] md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center lg:justify-center ">
        <div className="p-2 w-full md:flex md:justify-center  ">
          <img src={LoginImage} alt="IMAG" className="md:w-3/4  lg:w-3/4 " />
        </div>
        <div className="w-full md:mt-8 lg:mt-0">
          <div className="w-full ml-20 space-y-4">
            <div className="w-full text-xl font-bold text-GlobalColor ">
              Forgot Password
            </div>
            <div className="w-1/2 text-sm font-normal  text-black">
              Enter your <span className="font-bold">Email </span> and we will
              send you 6 digit code for verification
            </div>
          </div>
          <div className="w-full ml-20 space-y-4">
            <div className="w-full md:w-3/4 ">
              <LInputComp
                iconName={(className: string) => (
                  <User className="lg:w-5 lg:h-5  w-6 h-6 md:mr-2 -mt-3" />
                )}
                placeholder="Enter Email Id"
                id="email"
                type="text"
                errorMessage={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ""
                }
                value={formik.values.email}
                onchange={(e: any) => {
                  formik.setFieldValue("email", e);
                  dispatch(updateForgotEmailId(e));
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="w-full md:w-3/4 ">
              <Buttons
                btnName="Get OTP"
                data-qa="confirmbtn"
                type="submit"
                id="confirmbtn"
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EnterMobileNo;
