import React from "react";
import { ENUM_ADMIN_ROLE } from "../helpers";
import AppRoutes from "./AppRoutes";
import SuperAdminRoutes from "./SuperAdminRoutes";
import { useAuth } from "src/Modules/Auth/Core";
import DeveloperRotes from "./DeveloperRotes";
import MeterOperatorRoutes from "./MeterOperatorRoutes";

const RollbaseRoute = () => {
  const { auth } = useAuth();
  const rollbaseRouting = (status: any) => {
    switch (status) {
      case ENUM_ADMIN_ROLE?.ADMIN:
        // return <AppRoutes />;
        return <DeveloperRotes />;
        break;
      case ENUM_ADMIN_ROLE?.SUPER_ADMIN:
        // return <SuperAdminRoutes />;
        return <DeveloperRotes />;
        break;
        case ENUM_ADMIN_ROLE?.DEVLOPER:
          return <DeveloperRotes />;
          break;
          case ENUM_ADMIN_ROLE?.METER_OPERATOR:
            // return <MeterOperatorRoutes />;
            return <DeveloperRotes />;
            break;
      default:
        return <></>;
    }
  };

  return <>{rollbaseRouting && rollbaseRouting(auth?.role && auth?.role)}</>;
};

export default RollbaseRoute;
