import { useFormik } from 'formik'
import React, { useState } from 'react'
import OtpImage from '../../../../assets/Image/1231.png'

import { ResetPasswordeProps, ResetPasswordInitialVal, validationSchemaResetPassword } from './modules'
import {  useNavigate } from 'react-router-dom'

import { toast } from 'sonner';
import axios, { AxiosError } from 'axios'
import { ENUM_STATUS_CODE, stringifyObjectToRequestQuery } from 'src/helpers'
import { useDispatch, useSelector } from 'react-redux'

import { removeLoginPersiste, useAuth } from '../../Core'
import { encryptData } from 'src/Utility/crypto'
import { updateError, updateErrorCode, updateSuccessMess } from 'src/redux/Reducers/errorHadlingSlicer'
import InputPassword from 'src/components/commons/InputComponent/InputPassword'
import Buttons from 'src/components/commons/Buttons/Buttons'
import { forgotpasswordViaEmail } from 'src/services/services'
import { RootState } from 'src/redux/store'
import { loginReducerState } from 'src/redux/Reducers/loginSlicer'

const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const{auth}=useAuth()
  const { forgotEmailId, phone, forgotData,forgotToken } = useSelector((state: RootState) =>
    loginReducerState(state)
  );
const dispatch=useDispatch()
  const formik = useFormik<ResetPasswordeProps>({
    initialValues: ResetPasswordInitialVal,
    onSubmit: async (values, { resetForm, setValues }) => {
  

      let postData = {
        new_password:encryptData(values.new_password),
        confirm_password:encryptData(values.confirm_password)
       
      }

      if (postData) {
        henadleResetOtpApi(postData)
      }
      resetForm()
    
    },

    validationSchema: validationSchemaResetPassword
  })

  const henadleResetOtpApi = async (postData: any) => {
    const query=stringifyObjectToRequestQuery({token:forgotToken})
    setLoading(true)

    try {
      let apiResp: any = await forgotpasswordViaEmail(query,postData);
      dispatch(updateErrorCode(apiResp.code))
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        navigate('/login')
        removeLoginPersiste()
        dispatch(updateSuccessMess(apiResp.message))
       
        formik.resetForm();
        formik.setValues(ResetPasswordInitialVal)
      } else {
        dispatch(updateErrorCode(apiResp.code))
        dispatch(updateError(apiResp.message))
       
      }
      return apiResp.data
    } catch (err) {
      let error = err as Error | AxiosError
      if (axios.isAxiosError(error)) {
        dispatch(updateErrorCode(error.response?.data.code))
        dispatch(updateError(error.response?.data.message))
       
      }
      else {
        dispatch(updateError('An unexpected error occurred. Please try again later.'))
        
      }
    } finally {
      setLoading(false); 
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='w-full h-[100%] my-2 space-y-6 md:rounded-xl  md:border md:border-gray-100 md:bg-white lg:flex lg:flex-row-reverse lg:items-center'>
        <div className='w-full flex justify-center items-center '>
          <img src={OtpImage} className='w-3/4 lg:w-3/4' />
        </div>
        <div className='w-full ml-20 '>
          <div className='w-full  space-y-2'>
            <div className='w-full text-xl font-bold text-GlobalColor '>Reset Password</div>
            <div className='w-1/2 text-sm font-normal  text-black'>
              Set your new password so you can login and access
            </div>
          </div>

          <div  className='w-full md:w-3/4'>
          <InputPassword
             
              id='newPassword'
              placeholder='New Password'
              errorMessage={formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : ''}
              onchange={(value: string) => {
                formik.setFieldValue('new_password', value)
              }}
              values={formik.values.new_password}
              {...formik.getFieldProps('new_password')}
            />
            <div className='w-full md:-mt-4'>
              <InputPassword
             
                id='resetPassword'

                placeholder='Confirm Password'
                errorMessage={formik.touched.confirm_password && formik.errors.confirm_password ? formik.errors.confirm_password : ''}
                onchange={(value: string) => {
                  formik.setFieldValue('confirm_password', value)
                }}
                values={formik.values.confirm_password}
                {...formik.getFieldProps('confirm_password')}
              />
            </div>
       
          </div>
          <div className='w-full md:w-3/4 md:mt-6'>
            <Buttons btnName='CONFIRM' data-qa='confirmbtn' type='submit' id='resetbtn' loading={loading} />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ResetPassword
