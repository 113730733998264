import { cn, toAbsoluteUrl } from "src/helpers/index";

import { Link, useLocation } from "react-router-dom";
import {
  ChevronDown,
  ChevronRight,
  ChevronsUpDown,
  ChevronUp,
  LogOut,
  Mail,
  Phone,
  X,
} from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";

import Logo from "src/assets/Image/NPCL Saur Mitra 512w (1).png";

import { Button } from "../../../../components/ui/button";
import { useAuth } from "src/Modules/Auth/Core";
import { rollBaseModule } from "./rollBaseModule";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../../components/ui/collapsible";

export interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}
const OCT_LOGO = toAbsoluteUrl("/media/png/OCT_Logo_Horizontal.png");
const Npcl_Admin_Logo = toAbsoluteUrl("/media/png/Npcl_Admin_logo.png");
const Sidebarlayout = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const [subSidebarOpen, setSubSidebaropen] = useState(false);
  const [subMenuId, setSubmenuId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { auth, logoutHandling, logoutLoading } = useAuth();
  const { pathname } = useLocation();

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  // const Menu = rollBaseModule(auth?.role && auth?.role)
  // const Menu = auth && auth?.access
  const Menu = useMemo(() => {
    return auth?.access &&
      Array.isArray(auth?.access) &&
      auth?.access?.length > 0
      ? auth?.access
      : [];
  }, [auth]);

  // console.log(Menu)
  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72 flex-col overflow-hidden bg-primaryDarkBlue duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0  ${
        sidebarOpen ? "translate-x-0 " : "-translate-x-full "
      }`}
    >
      <div className="w-full  h-full">
        <div className=" mt-2 md:mt-0 flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
          <div>
            <img src={Npcl_Admin_Logo} alt="Logo" className="w-[80%]" />
          </div>

          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="block lg:hidden"
          >
            <X size={20} className="text-white" />
          </button>
        </div>
        <div className=" flex flex-col h-[60%] scrollbar-thumb-[#026597] scrollbar-track-sky-600 scrollbar-thin overflow-y-scroll duration-300 ease-linear pl-4 mt-2">
          <ul className={` space-y-1 }`}>
            {Menu.map((menu, index) => (
              <li key={index}>
                {menu.options !== undefined ? (
                  <div className="">
                    <Link
                      to={menu.path}
                      className={`flex items-center justify-between space-x-4 font-semibold text-sm py-[12px] px-4 rounded-l-md 
                        ${
                          menu.path && pathname.includes(menu.path)
                            ? "bg-white "
                            : ""
                        }`}
                      onClick={() => {
                        setSubmenuId(index);
                        if (subMenuId === index) {
                          setSubSidebaropen(!subSidebarOpen);
                        } else {
                          setSubSidebaropen(true);
                          // console.log("open")
                        }
                        setSidebarOpen(!sidebarOpen);
                      }}
                    >
                      <span
                        className={cn(" text-white text-sm font-normal", {
                          "text-text_primaryDarkBlue font-medium":
                            menu.path && pathname.includes(menu.path),
                        })}
                      >
                        {menu.name}
                      </span>
                      <span>
                        {menu.path &&
                        pathname.includes(menu.path) &&
                        subSidebarOpen ? (
                          <ChevronUp
                            size={20}
                            className="text-text_primaryDarkBlue"
                          />
                        ) : (
                          <ChevronDown
                            size={20}
                            className="text-text_primaryDarkBlue"
                          />
                        )}
                      </span>
                    </Link>

                    <ul
                      className={cn(
                        "ml-8 mt-2 hidden border-l-[2px] pr-4 border-white",
                        {
                          block:
                            subSidebarOpen && pathname.includes(menu?.path),
                        }
                      )}
                    >
                      {menu.options.map((option: any, optionIndex: number) => (
                        <li
                          key={optionIndex}
                          onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                          <Link
                            to={option.path}
                            className={cn(
                              "flex items-center  justify-between space-x-4  ml-4  py-[10px] px-4 rounded-md text-white font-normal",
                              {
                                "bg-[#2D2F39] text-white font-medium":
                                  menu.path && pathname === option?.path,
                              }
                            )}
                          >
                            <span className={cn("text-xs font-normal  ")}>
                              {option.name}
                            </span>
                            <span>
                              {option.path && pathname.includes(option.path) ? (
                                <ChevronRight
                                  size={20}
                                  className="text-white"
                                />
                              ) : (
                                <></>
                              )}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Link
                    to={menu.path}
                    className={`flex items-center justify-between space-x-4  py-[12px] px-4 rounded-l-full ${
                      menu.path && pathname.includes(menu.path)
                        ? "bg-white text-white"
                        : ""
                    }`}
                    onClick={() => {
                      setSubSidebaropen(
                        subSidebarOpen ? !subSidebarOpen : subSidebarOpen
                      );
                      setSidebarOpen(!sidebarOpen);
                    }}
                  >
                    {/* <span>{menu.iconName(cn('text-white text-sm font-bold', { 'text-textLightBlack': menu.path && pathname.includes(menu.path) }))}
                </span> */}
                    <span
                      className={cn(" font-normal text-white  text-sm", {
                        "text-text_primaryDarkBlue font-medium":
                          menu.path && pathname.includes(menu.path),
                      })}
                    >
                      {menu.name}
                    </span>
                    <span>
                      {menu.path && pathname.includes(menu.path) ? (
                        <ChevronRight size={20} />
                      ) : (
                        <></>
                      )}
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="absolute bottom-8 space-y-6">
        <div className=" cursor-pointer  " onClick={() => logoutHandling()}>
          {/* <span>Logout</span> <LogOut className='w-4 h-4'/>  */}
          <Button className="bg-light_red hover:bg-light_red ml-6 text-white flex items-center space-x-2">
            {/* <span>Logout</span> <LogOut className="w-4 h-4" /> */}
            {logoutLoading ? (
              "Please Wait"
            ) : (
              <>
                <span>Logout</span> <LogOut className="w-4 h-4" />
              </>
            )}
          </Button>
        </div>
        <div className=" text-white text-xs space-y-2  ">
          <div className="flex items-center ml-8 space-x-2">
            In association with
          </div>
          <div className="flex items-center ml-6 space-x-2 ">
            <img src={OCT_LOGO} alt="oct-logo" className="w-[126px] h-[35px]" />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebarlayout;
