import { Input } from "src/components/ui/input";
import React, { useState, useRef, useMemo, FormEvent, useEffect } from "react";
import { Button } from "src/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";

import axios, { AxiosError } from "axios";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../Core";
import { ENUM_STATUS_CODE, NUMBER_PATTERN } from "src/helpers";
import {
  loginReducerState,
  updateForgotToken,
} from "src/redux/Reducers/loginSlicer";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Reducers/errorHadlingSlicer";
import Buttons from "src/components/commons/Buttons/Buttons";
import { resentForgotOtp, validateForgotOtp } from "src/services/services";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../../../components/ui/input-otp";

const ForgotOtp = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
  const [timeRemaining, setTimeRemaining] = useState(180);
const [newotp, setNewOtp] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forgotEmailId, phone, forgotData } = useSelector((state: RootState) =>
    loginReducerState(state)
  );
  const { auth, saveAuth } = useAuth();

  const phoneNumber = useMemo(
    () => (phone ? phone.toString().slice(-4) : "0789"),
    [phone]
  );


  const otpVerification = async () => {
    setLoading(true);
    try {
      let postData = {
      
        user: forgotData?.user,
        otp: newotp,
      };
      let apiResp = await validateForgotOtp(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
       
        dispatch(updateForgotToken(apiResp.data?.tokens?.resetPasswordToken));
        navigate("reset-password");
      } else {
        dispatch(updateErrorCode(apiResp.code));
        dispatch(updateError(apiResp.message));
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateErrorCode(error.response?.data?.code));
        dispatch(updateError(error.response?.data.message));
      } else {
        // toast.error(error.message)

        dispatch(updateError(error.message));
      }
    } finally {
      setLoading(false);
    }
  };

  const resendAgainLogin = async () => {
    setNewOtp("");
    try {
      let apiResp = await resentForgotOtp({ user: forgotData?.user });
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        dispatch(updateSuccessMess("Otp sent sucessfully"));
        // toast.success('Otp sent sucessfully')
        dispatch(
          updateSuccessMess(
            "We've resent the OTP to your registered number/email"
          )
        );
        setTimeRemaining(180);
      } else {
        dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(
          updateError("An unexpected error occurred. Please try again later.")
        );
        dispatch(updateErrorCode(error.response?.data?.code));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(
          updateError("An unexpected error occurred. Please try again later.")
        );
        // toast.error(error.message)
      }
    } finally {
      // Set loading state to false when request completes (whether success or failure)
    }
  };

  

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === "Enter" && newotp && newotp.length >= 6) {
                event.preventDefault();

                otpVerification();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [newotp]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeRemaining]);

    const isOtpComplete = useMemo(
        () => (newotp ? newotp.length < 6 : true),
        [newotp]
      );
    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

  return (
    <div className="w-full lg:flex lg:flex-col lg:items-center lg:justify-center">
      <h1 className="text-GlobalColor font-bold text-xl text-center md:text-2xl ">
        Verify OTP
      </h1>
      <div className="w-full flex  justify-center items-center mt-6">
        <p className=" text-zinc-600 text-sm text-left md:text-center lg:text-left  w-3/4 flex font-[500] md:text-sm  lg:font-normal lg:w-3/5">
          Please enter the verification code received on your registered mobile
          number
        </p>
      </div>

      <p className="font-medium  text-black text-sm my-2 text-center   lg:font-medium  lg:text-left w-full lg:w-3/5 lg:my-3 ">
        ******{phoneNumber}
      </p>
      <p
        className="w-full font-normal text-center text-zinc-600 text-sm mt-2 mb-6 md:text-sm  lg:font-normal lg:text-left  lg:w-3/5"
        data-testid="timer-otp"
      >
        The code will expire in {minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </p>
      {/* <div className="w-full flex space-x-4 justify-center ">
        {otp.map((cur, index) => (
          <Input
            key={index}
            maxLength={1}
            className="w-10 h-10 border-input border-grayLable rounded-lg  "
            value={cur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, index)
            }
            onFocus={(e) => e.target.select()}
            ref={(el) => (inputRefs.current[index] = el)}
            data-qa={`otp_${index}`}
          />
        ))}
      </div> */}

<div className="w-full flex  justify-center">
                <InputOTP
                    maxLength={6}
                    pattern={NUMBER_PATTERN}
                    className=""
                    value={newotp}
                    onChange={(e: string) => setNewOtp(e)}
                    data-testid="otp-input"
                >
                    <InputOTPGroup className="">
                        <InputOTPSlot index={0} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={1} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={2} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={3} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={4} />
                    </InputOTPGroup>
                    <InputOTPGroup className="">
                        <InputOTPSlot index={5} />
                    </InputOTPGroup>
                </InputOTP>
            </div>
      <div className="w-full px-8 mt-4 md:w-3/4 lg:flex lg:justify-center">
        <Buttons
          onClick={otpVerification}
          btnName={`${isOtpComplete ? "CONFIRM" : "VERIFY"}`}
          data-qa="otpVerifys"
          id="otpVerify"
          disabled={timeRemaining === 0 || isOtpComplete}
          loading={loading}
        />
      </div>
      {/* <h3 className="text-zinc-500 text-sm font-medium text-center mt-4 md:my-4 ">
        Didn’t receive any code?
        <Button
          className="text-red-500 text-sm font-semibold cursor-pointer ml-1 bg-white hover:bg-white border border-none"
          role="button"
          disabled={!(timeRemaining === 0)}
          onClick={resendAgainLogin}
          variant={"link"}
        >
          Resend
        </Button>
      </h3> */}
    </div>
  );
};

export default ForgotOtp;
