import { ColumnDef } from "@tanstack/react-table";
// import Charts from "src/components/ui/common/Chart/Charts";
import { Switch } from "src/components/ui/switch";
import Datehistory from "./Datehistory";
import ActionBuy from "./ActionBuy";
import { ENUM_CONSUMER_TYPE } from "../../../../../../../../helpers";
import ActionButtonAll from "./ActionButtonAll";
import NetBefit from "./NetBefit";

export type InvoiceListViewAllProps = {
  name: string;
  consumerNumber: string;
  tradeUnit: number;
  consumedUnit: number;
  buyerPayable: number;
  buyerSaving: number;
  consumerCode: string;
  user: string;
  consumerType: string;
  generatedUnit: number;
  sellerReceivable: number;
  sellerEarning: number;
};

export const AllColumn: ColumnDef<InvoiceListViewAllProps>[] = [
  {
    accessorKey: "consumerNumber",
    header: "Consumer Number",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "consumerCode",
    header: "Platform Id",
  },

  {
    accessorKey: "tradeUnit",
    header: "Scheduled Energy on Platform (kWh)",
    cell: ({ row }: any) => {
      let val: number = row.getValue("tradeUnit");
      return (
        <div className="text-center">
          {val || val === 0 ? val?.toFixed(2) : "-"}
        </div>
      );
    },
  },
  {
    accessorKey: "consumedUnit",
    header: "Purchased Energy from Platform (kWh)",
    cell: ({ row }: any) => {
      let val: number = row.getValue("consumedUnit");
      return (
        <div className="text-center">
          {val || val === 0 ? val?.toFixed(2) : "-"}
        </div>
      );
    },
  },
  {
    accessorKey: "generatedUnit",
    header: "Sold Energy to P2P (kWh)",
    cell: ({ row }: any) => {
      let val: number = row.getValue("generatedUnit");
      return (
        <div className="text-center">
          {val || val === 0 ? val?.toFixed(2) : "-"}
        </div>
      );
    },
  },
  {
    accessorKey: "buyerPayable",
    header: "Total Payable ( ₹ )",
    cell: ({ row }: any) => {
      let val: number = row.getValue("buyerPayable");
      return (
        <div className="text-center">
          {val || val === 0 ? val?.toFixed(2) : "-"}
        </div>
      );
    },
  },
  {
    accessorKey: "sellerReceivable",
    header: "Total Receivable ( ₹ )",
    cell: ({ row }: any) => {
      let val: number = row.getValue("sellerReceivable");
      return (
        <div className="text-center">
          {val || val === 0 ? val?.toFixed(2) : "-"}
        </div>
      );
    },
  },

  {
    accessorKey: "buyerSaving",
    header: "Net Benefit ( ₹ )",
    cell: ({ row: { original } }) => {
    
        return <NetBefit {...original} />;
      },
    },

  
  {
    id: "viewDetailsAll",
    header: "Details",
    cell: ({ row: { original } }) => {
    
      return <ActionButtonAll {...original} />;
    },
  },
];
