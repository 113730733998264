export const SSE_URL = process.env.REACT_APP_BASE_URL_SSE
export const API_URL = process.env.REACT_APP_BASE_URL + "/api/v1"
export const BLOCKCHAIN_API_URL = process.env.REACT_APP_BASE_URL_BLOCKCHAIN
export const BLOCKCHAIN_API_URL_CLIENT_ORG_2 = process.env.REACT_APP_BASE_URL_BLOCKCHAIN_CLIENT_ORG_2
export const BLOCKCHAIN_API_URL_CLIENT_ORG_1 = process.env.REACT_APP_BASE_URL_BLOCKCHAIN_CLIENT_ORG_1
export const AUTH_URL = process.env.REACT_APP_BASE_URL_AUTH + '/api/v1/web'
export const API_URL_SERVICES = process.env.REACT_APP_BASE_URL + "/api/v1"
export const API_URL_META_BASE = process.env.REACT_APP_BASE_URL_META_BASE_URL
export const API_URL_MAP = process.env.REACT_APP_BASE_URL_MAP
export const BILL_URL = process.env.REACT_APP_BASE_URL_SERVICES + "/api/v1"
export const REPORT_URL = process.env.REACT_APP_BASE_URL_REPORT + "/api/v1"
// Dashboard

export const BLOCKCHAIN_DETAIL_API = BLOCKCHAIN_API_URL + "/block/getAll"
export const BLOCKCHAIN_DETAIL_SSE_API = BLOCKCHAIN_API_URL + "/block/events"
export const BLOCKCHAIN_ID_API = BLOCKCHAIN_API_URL + '/transaction/getAll'
export const BLOCKCHAIN_TRANSACTION_DETAILS_API = (id: string) => BLOCKCHAIN_API_URL + '/transaction/get' + "/" + id
// export const USER_DASHBOARD_SUMMARY = API_URL + '/user/dashboard/summary'
export const USER_DASHBOARD_SUMMARY = API_URL + '/dashboard/summary'

export const EVENT_TIMELINE_SSE = BLOCKCHAIN_API_URL + "/transaction/events"
// export const EVENT_TIMELINE_STATTIC = BLOCKCHAIN_API_URL_CLIENT_ORG_1 + "/transaction/getAllByEvent"
export const EVENT_TIMELINE_STATTIC = BLOCKCHAIN_API_URL_CLIENT_ORG_1+"/chaincode/getAllEventsByCc"
export const CONSUMPTIONGENRATIONSETTLEMENT = API_URL + '/dashboard/gencon-chart'
// SSE URL

export const SSE_OTS_URL = SSE_URL + '/ots'
export const SSE_LTP_URL = SSE_URL + '/ltp'
export const SSE_OTS_URL_WEEKLY = SSE_URL + '/ots/weekly'
export const SSE_OTS_URL_MONTHLY = SSE_URL + '/ots/monthly'
export const SSE_MARKETH_DEPTH = SSE_URL + "/market_depth"
export const SSE_OTS_NEW_URL = SSE_URL + '/web/ots'

// Testing Verification Report Api

// export const TESTING_VERIFICATION_API = API_URL + '/order/fetch-data'
// export const TESTING_VERIFICATION_API = API_URL + '/order/fetch-data-tb'
export const TESTING_VERIFICATION_API = API_URL +'/report/end-to-end'

// Drop down
export const URL_GET_ALL_STATE = API_URL + "/state/web/getAll";

// user list
export const ALL_USER_LIST = API_URL + "/user/"
export const GET_ONBOARD_USER = API_URL + "/user/onboard/"
export const ALL_USER_LIST_DOWNLOAD_EXCEL = API_URL + "/user/download"
export const USER_PROFILE = (id: string) => API_URL + '/user/profile' + "/" + id
export const USER_LOAD_METER_PLANT_DETAIL = (id: string) => API_URL + '/user/details' + "/" + id
export const PROFILE_APPROVE_REJECT = API_URL + '/user/user-status'
export const ADD_USER_URL = API_URL + '/user'
export const UPDATE_STATUS = API_URL + "/user/update/status"
export const USERS_ACTIVE_DEACTIVE_DETAILS=API_URL+"/user/user-status/transactions"


// Login Url  

// export const URL_LOGIN_OTP = AUTH_URL + "/auth/login-by-cno-phone";
export const URL_LOGIN_OTP = API_URL + "/auth/login";
export const URL_LOGIN_OTP_VALIDATE = API_URL + "/auth/validate-otp";
export const URL_VERIFY_TOKEN = API_URL + "/auth/verify-token";
export const URL_LOGOUT_API=API_URL+"/auth/logout"
export const REFRESH_TOKEN_API=API_URL+"/auth/refresh-tokens"
export const URL_NEW_RESEND_OTP=API_URL+"/auth/resend-otp"

//forgotPassword

export const URL_ENTER_EMAIL_ID=API_URL+"/auth/forgot-password-2"
export const URL_FORGOT_PASSWORD=API_URL+"/auth/reset-password"
export const URL_VALIDATE_FORGOT_OTP=API_URL+"/auth/validate-reset-otp"
export const URL_RESENT_OTP=API_URL+"/auth/resend-otp"
// Configration panel & tarrif

// export const URL_GET_CONFIGRATION_TARRIF = (id: string) => BLOCKCHAIN_API_URL_CLIENT_ORG_1 + '/chaincode/getDiscom' + "/" + id
export const URL_GET_CONFIGRATION_TARRIF = (id: string) => API_URL + '/discom-charges/charges' + "/" + id

// export const URL_UPDATE_CONFIGRATION_TARRIF = BLOCKCHAIN_API_URL_CLIENT_ORG_1 + "/chaincode/wrapper/updateDiscomCharges"
export const URL_UPDATE_CONFIGRATION_TARRIF = API_URL + "/discom-charges/submit"
export const URL_DOWNLOAD_SAMPLE_EXCEL = API_URL + "/tariff-plan/download-sample-tariff"
export const URL_UPLOAD_BULK_EXCEL = API_URL + "/tariff-plan/upload-bulk-excel"

// Bill 

export const URL_GET_BILL = API_URL + "/bills/getAll"
export const BILL_API_URL = API_URL + "/bills/summary-new";
export const DAILY_TRANSACTION_INVOICE_URL = API_URL + "/bills/all-transactions-new"
export const TRANSACTION_INVOICE_URL = API_URL + "/bills/daily-summary-new"
export const CONSUMER_DETAILS_URL = (id: string) => API_URL + '/user/contract-details' + "/" + id
export const URL_SEND_BILL_EMAIL = REPORT_URL + "/send-monthly-bill-user"
export const URL_INVOICE_LIST_VIEW = API_URL + "/invoice/fetch-billing-list"
export const SETTELED_AND_ALL_TRANSACTION=API_URL+"/bills/all-transactions-new"

// Tarrif

export const URL_GET_ALL_TARRIF = API_URL_SERVICES + "/tariff-plan/fetch-all"
export const CREATE_TARRIF_PLAN = API_URL_SERVICES + "/tariff-plan/create"
export const UPDATE_TARRIF_PLAN = (id: string) => API_URL_SERVICES + "/tariff-plan/update" + "/" + id
export const PARENT_CODE_API = API_URL_SERVICES + "/tariff-plan/dd/parent-code"
// Transaction
export const URL_GET_ALL_TRANSACTIONS = BLOCKCHAIN_API_URL + "/transaction/getAllTransactions"
export const URL_GET_ALL_FAILED_TRANSACTIONS = BLOCKCHAIN_API_URL + "/transaction/getFailedTransactions"
export const URL_RETRY_FAILED_TRANSACTIONS = BLOCKCHAIN_API_URL_CLIENT_ORG_1 + "/retry"

// metabaseurl

export const URL_METABASE = API_URL + '/metabase/url'
export const URL_METABASE_CARD_URL = API_URL + '/metabase/fetchAll'
export const URL_CREATE_METABASE_FORM = API_URL + "/metabase/create"
export const URL_UPDATE_METABASE_FORM = (id: string) => API_URL + '/metabase/update' + "/" + id

// map url
export const URL_MAP = API_URL_MAP

//  drop url
export const URL_USER_LIST = API_URL + "/user/get/userlist"

// Meter Reading Report
export const URL_METER_READING_REPORT = API_URL + "/meter/dmr-report"
export const URL_GET_PREVIOUS_DAY_READING = API_URL + "/meter/latest-reading"
export const URL_UPDATE_METER_READING = API_URL + "/meter/daily-meter-reading"
export const URL_DOWNLOAD_METER_READING = API_URL + "/meter/dmr-report-download"
export const URL_METER_READING_SUMMARY = API_URL + "/meter/dmr-summary"

// new Meter reading Api

export const URL_METER_READING_USER_LIST=API_URL + "/meter-reading-lp/fetch-user-list"
export const URL_USERS_METER_READING_DATA=API_URL+"/meter-reading-lp/fetch-meter-data"
export const URL_ADD_METER_READING=API_URL+"/meter-reading-lp/add-reading"
export const URL_METER_READING_DROPDOWN_LIST=API_URL+"/meter-reading-lp/dropdown"



// gross capture form
export const URL_GET_ALL_GROSS_CAPTURE = API_URL + "/gross-energy/fetch-all"
export const URL_CREATE_GROSS_CAPTURE_FORM = API_URL + "/gross-energy/create"
export const URL_UPDATE_GROSS_CAPTURE_FORM = (id: string) => API_URL + "/gross-energy/update" + "/" + id

// Admin Banner
export const URL_GET_ALL_BANNER_LIST = API_URL + '/banner/get'
export const URL_CREATE_BANNER_LIST = API_URL + '/banner/create'
export const URL_UPDATE_BANNER_LIST = (id: string) => API_URL + '/banner/update' + "/" + id
export const URL_UPLOAD_IMAGE = API_URL + '/upload'

// Discom History
// export const URL_DISCOM_HISTORY = (id: string) => BLOCKCHAIN_API_URL_CLIENT_ORG_1 + "/chaincode" + "/" + id
export const URL_DISCOM_HISTORY = (id: string) => BLOCKCHAIN_API_URL_CLIENT_ORG_1 + "/discomCharges/getDiscomHistory" 
export const URL_CUSTOMER_TARRIFF_HISTORY=BLOCKCHAIN_API_URL_CLIENT_ORG_1+"/tariffCode/getTariffCodeHistory"

// Gate Closure

export const URL_GET_ALL_GATE_CLOSURE = API_URL + "/gate-closer/fetch-all"
export const URL_UPDATE_GATE_CLOSURE = (id: string) => API_URL + "/gate-closer/update" + "/" + id
export const URL_CREATE_GATE_CLOSURE = API_URL + "/gate-closer/create"


// Time block

export const URL_GET_ALL_TIMEBLOCK_LIST=API_URL+"/time-block/fetch-all"
export const URL_POST_TIMEBLOCK=API_URL+"/time-block/save-time-block-config"

// Co2 Configuration Screen

export const CREATE_CONFIGURATION_URL=API_URL+"/environment/create"
export const UPDATE_CONFIGURATION_URL=(id: string) =>API_URL+"/environment/update"+"/"+id
export const GET_ALL_CONFIGURATION_URL=API_URL+"/environment/fetch-all"
export const GET_UPDATED_CONFIGURATION_DATA =(id: string)=>API_URL+"/environment/fetch"+"/"+id
export const GET_HISTORY_CONFIGURATION_DATA =(id: string)=>API_URL+"/environment/fetch/history"+"/"+id
export const URL_PARAMETER_NAME_DROPDOWN=API_URL+"/environment/dd"


// Energy Injection and Drawal Analysis Report

export const GET_ENERGY_INJECTION_AND_DRAWAL_ANYALYSIS_REPORT =API_URL+"/report/energy-inj-drawl-unit"
export const DOWNLOAD_ENERGY_INJECTION_AND_DRAWAL_ANYALYSIS_REPORT=API_URL+"/report/dwd-energy-inj-drawl-unit"

// Collection Report

export const GET_COLLECTION_REPORT=REPORT_URL+"/financial-report/fetch-charges"
export const GET_USER_SUMMARY_AND_EARNING_REPORT=REPORT_URL+"/report/daily-user-summary-earn"

// Missing Meter Readings Onboarded Users

export const GET_METER_READINGS_NOT_FOUND_ONBOARD_USER=REPORT_URL+"/report/user-mr-unavailable"

// chain code name dropdown

export const GET_CHAINCODE_NAME_DROPDOWN=BLOCKCHAIN_API_URL_CLIENT_ORG_1+"/GetAllSmartContract"