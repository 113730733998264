import React, { useMemo } from 'react'
import { InvoiceListViewAllProps } from './AllColumn'
import { ENUM_CONSUMER_TYPE } from '../../../../../../../../helpers';

const NetBefit = ({buyerSaving,sellerEarning,consumerType}:InvoiceListViewAllProps) => {
    const val=useMemo(()=>{
        if (consumerType === ENUM_CONSUMER_TYPE.CONSUMER) {
            return buyerSaving !== undefined && buyerSaving !== null ? Number(buyerSaving).toFixed(2) : "-";
          } else {
            return sellerEarning !== undefined && sellerEarning !== null
              ? Number(sellerEarning).toFixed(2)
              : "-";
          }
    },[buyerSaving,sellerEarning,consumerType])
  return (
    <div>
{val}
    </div>
  )
}

export default NetBefit