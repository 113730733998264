import React, { useState } from "react";

import LoginImage from "src/assets/Image/LoginImg.png";
// import LoginViaPhone from './LoginViaPhone'
import { cn, toAbsoluteUrl } from "src/helpers";
import Login from "./Login";
import { Card } from "../../../../components/ui/card";
// import LoginViaConsumerNo from './LoginViaConsumerNo'

const BLUE_BACKGROUND = toAbsoluteUrl("/media/png/background_login.png");
const RP_SANVIV = toAbsoluteUrl("/media/png/RP_SANJIV.png");
const NPCL = toAbsoluteUrl("/media/png/NPCL.png");
const NPCL_SAUURL_MITRA = toAbsoluteUrl("/media/png/NPCL_Saur_mitra.png");
const OCT_LOGO = toAbsoluteUrl("/media/png/OCT-Logo-PNG-LOGIN.png");
const P2P_IMG = toAbsoluteUrl("/media/png/P2P_LOGIN.png");
const NPCL_MOSCOT_LEFT = toAbsoluteUrl("/media/png/NPCL_Mascot_Left.png");
const NPCL_MOSCOT_RIGTH = toAbsoluteUrl("/media/png/NPCL_Mascot_Right.png");
const ILoginScreen = () => {
  return (
    <div
      className="w-full h-[100%] font-Roboto md:rounded-xl bg-blue-100   md:border md:border-gray-100 lg:overflow-y-hidden grid grid-cols-1 overflow-y-scroll  lg:grid-cols-4 xl:grid-cols-5"
      style={{
        backgroundImage: `url(${BLUE_BACKGROUND})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // width: "100%",
        // height: "100%",
      }}
    >
      <div className="col-span-1 order-2 lg:order-1 lg:col-span-2 px-10 lg:my-auto space-y-5 ">
        <div className="flex justify-between items-center">
          <img alt="RP_SANVIV" src={RP_SANVIV} />
          <img alt="NPCL" src={NPCL} />
        </div>
        <Card className="px-3 pt-3 pb-10">
          <div className="text-text_primaryDarkBlue text-xl font-bold ">
            Login
          </div>
          <Login />
        </Card>
      </div>

      <div className=" col-span-1 order-1 lg:order-2 lg:col-span-2 xl:col-span-3 lg:m-auto space-y-2  ">
        <div className="space-y-2">
          <div className="font-semibold italic text-black text-xl xl:text-2xl text-center">
            Presenting
          </div>
          <div className="flex justify-center items-center mt-10">
            <img src={NPCL_SAUURL_MITRA} alt="NPCL_SAUURL_MITRA" />
          </div>
        </div>

        <div className="w-full flex relative justify-between space-x-6">
          <div className="absolute -mt-6">
            <img src={NPCL_MOSCOT_LEFT} className="w-[106px] h-[207px]" />
          </div>
          <div className="w-full space-y-2 mt-2">
            <div className="text-xl xl:text-2xl text-center  ">
              A <b>Peer to Peer Energy Trading</b> Platform
            </div>
            <div className="w-full">
              <img
                src={P2P_IMG}
                alt="P2P_IMG"
                className="lg:w-[650px] xl:w-[650px] lg:h-[290px] xl:h-[320px]"
              />
            </div>
          </div>
          <div className="absolute -right-6 -mt-6">
            <img src={NPCL_MOSCOT_RIGTH} className="w-[106px] h-[207px]" />
          </div>
        </div>

        <div className=" xl:flex items-center justify-center text-xs font-medium space-x-1">
          <div>In association with</div>
          <img src={OCT_LOGO} alt="OCT_LOGO" className="w-[160] h-[60px]" />
          {/* <div>For more information or registration</div>
          <div className="underline underline-offset-4 hover:underline-offset-8 text-text_primaryDarkBlue font-semibold cursor-pointer">
            click here
          </div> */}
        </div>
      </div>


    </div>

 
  );
};

export default ILoginScreen;
