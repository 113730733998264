import { Link } from "react-router-dom";
import { Badge } from "../../../../../../../../components/ui/badge";
import React, { useMemo } from "react";
import { InvoiceListView } from "../../..";
import { InvoiceListViewProps } from "./columnsInvoiceListViewBuy";
import {
  ENUM_CONSUMER_TYPE,
  ENUM_PURCHASE_TYPE,
} from "../../../../../../../../helpers";
import { InvoiceListViewAllProps } from "./AllColumn";

type Props = {};

const ActionButtonAll = ({ user, consumerType }: InvoiceListViewAllProps) => {
  const { state, updateState } = InvoiceListView();


  const conType = useMemo(
    () =>
      consumerType === ENUM_CONSUMER_TYPE.CONSUMER
        ? ENUM_PURCHASE_TYPE.BUY
        : consumerType === ENUM_CONSUMER_TYPE.PROSUMER
        ? ENUM_PURCHASE_TYPE.SELL
        : "",
    [consumerType]
  );
  
  return (
    <>
      {consumerType === ENUM_CONSUMER_TYPE?.CONSUMER ? (
        <Link
          to={`/invoice/details?start_date=${state?.filter?.startDate}&end_date=${state?.filter?.endDate}&purchase_type=${conType}&user=${user}`}
        >
          <Badge
            className="w-32 flex cursor-pointer justify-center space-x-3 items-center text-text_primaryDarkBlue bg-white hover:bg-white border border-border_primaryDarkBlue h-8 text-xs font-medium "
            // onClick={actionClick}
          >
            View Details
          </Badge>
        </Link>
      ) : consumerType === ENUM_CONSUMER_TYPE?.PROSUMER ? (
        <Link
          to={`/invoice/details?start_date=${state?.filter?.startDate}&end_date=${state?.filter?.endDate}&purchase_type=${conType}&user=${user}`}
        >
          <Badge className="w-32 flex cursor-pointer justify-center space-x-3 items-center text-text_primaryDarkBlue bg-white hover:bg-white border border-border_primaryDarkBlue h-8 text-xs font-medium ">
            View Details
          </Badge>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionButtonAll;
