import * as Yup from "yup";

// For Mobile screen
export interface ForgotPasswordMobileNoProps {
  email: string | undefined;
}
export const validationSchemaForgotPasswordMobile = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format") // Ensures the email is in a valid format
    .required("Email is required"),
});

export const ForgotPasswordMobileNoInitialVal = {
  email: "",
};

//   Reset password

export interface ResetPasswordeProps {
  confirm_password: string | undefined;
  new_password: string | undefined;
}
export const validationSchemaResetPassword = Yup.object().shape({
  new_password: Yup.string()
    .required("Password is required")
    .min(8, "Password must contain 8 or more characters")
    .max(18, "Password must be less than or equal to 18 characters")
    .test(
      "lowercase",
      "Password must contain at least 1 lowercase letter",
      (value) => /[a-z]/.test(value)
    )
    .test(
      "uppercase",
      "Password must contain at least 1 uppercase letter",
      (value) => /[A-Z]/.test(value)
    )
    .test("number", "Password must contain at least 1 number", (value) =>
      /[0-9]/.test(value)
    )
    .test(
      "special",
      "Password must contain at least 1 special character",
      (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .min(8, "Password must be at least 8 characters")
    .max(18, "Password less than 18 characters")
    .test(
      "lowercase",
      "Password must contain at least 1 lowercase letter",
      (value) => /[a-z]/.test(value)
    )
    .test(
      "uppercase",
      "Password must contain at least 1 uppercase letter",
      (value) => /[A-Z]/.test(value)
    )
    .test("number", "Password must contain at least 1 number", (value) =>
      /[0-9]/.test(value)
    )
    .test(
      "special",
      "Password must contain at least 1 special character",
      (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
    )
    .test(
      "passwords-match",
      "Confirm Password does not match",
      function (value) {
        return this.parent.new_password === value;
      }
    ),
});

export const ResetPasswordInitialVal = {
  confirm_password: "",
  new_password: "",
};
