import { ENUM_STATUS_CODE } from "../../helpers";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";
import axios, { AxiosError } from "axios";
import { getchaincodeDropdown } from "src/services/services";
import { RootState } from "../store";

interface DropDownState {
  chaincodeNameDropdown: any[];
  loading: boolean;
}

const IDropdownState: DropDownState = {
  chaincodeNameDropdown: [],
  loading: false,
};

export const chaincodeDropdownList: any = createAsyncThunk(
  "chaincodeName/dropdown",
  async (data: any, thunkApi: any) => {
    try {
      const response: any = await getchaincodeDropdown();

      if (response.code === ENUM_STATUS_CODE?.SUCCESS && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;

        thunkApi.dispatch(updateError(errorMessage));
        thunkApi.dispatch(updateErrorCode(response?.code));
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(updateError(error.response?.data.message));
        thunkApi.dispatch(updateErrorCode(error.response?.data?.code));
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        thunkApi.dispatch(updateError(error.message));
      }

      thunkApi.dispatch(updateError(error.message));

      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const dropdownSlicer = createSlice({
  initialState: IDropdownState,
  name: "dropdownSlicer",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(chaincodeDropdownList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chaincodeNameDropdown = payload;
      // console.log(payload)
    });
    builder.addCase(chaincodeDropdownList.pending, (state) => {
      state.loading = true;
      state.chaincodeNameDropdown = [];
    });
    builder.addCase(chaincodeDropdownList.rejected, (state, { payload }) => {
      state.loading = false;
      state.chaincodeNameDropdown = [];
    });
  },
});

export const {} = dropdownSlicer.actions;
  
  export const dropdownReducerState = (state: RootState) => state.dropdown;
  export default dropdownSlicer.reducer;
