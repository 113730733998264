import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  errorReducerState,
  updateResetError,
} from "src/redux/Reducers/errorHadlingSlicer";
import { RootState } from "src/redux/store";

import { useLocation, useNavigate } from "react-router-dom";
import { useOnlineStatus } from "../Auth/Core/OnlineStatusContext";
import { convertToCamelCase, ENUM_STATUS_CODE } from "src/helpers/index";
import { toast, Toaster } from "sonner";
import { useAuth } from "../Auth/Core";
const ErrorBoundryMain = () => {
  const {
    errorMessage,
    isError,
    isWarning,
    warningMessage,
    errorCode,
    successMessage,
    isInfo,
    infoMessage,
  } = useSelector((state: RootState) => errorReducerState(state));
  // const { toast } = useToast();
  const dispatch = useDispatch();
  const { setCurrentUser, logoutHandling, currentUser } = useAuth();
  const { pathname } = useLocation();
  const { isOnline } = useOnlineStatus();
  const navigate=useNavigate()
  // console.log(pathname)
  useEffect(() => {
    if (isError) {
      if (errorMessage) {
        // console.log(errorMessage)
        toast.error(convertToCamelCase(errorMessage));
      }
    } else if (isWarning) {
      toast.warning(convertToCamelCase(warningMessage));
    } else if (!isOnline) {
      toast.error("You lost your internet connection");
    } else if (isInfo) {
      if (infoMessage) {
        toast.info(infoMessage);
      }
    }
    dispatch(updateResetError());
  }, [
    errorMessage,
    isError,
    isWarning,
    warningMessage,
    isOnline,
    isInfo,
    infoMessage,
  ]);

  useEffect(() => {
    if (
      errorCode === ENUM_STATUS_CODE?.AUTHENDICATE ||
      errorCode === ENUM_STATUS_CODE?.RUN_TIME_ERROR
    ) {
      toast.error(errorMessage, {
        duration: 2000,
      });

      if (currentUser?.isAuthenticated) {
        logoutHandling();
      } else return;
    } else if (
      (errorCode === ENUM_STATUS_CODE?.SUCCESS ||
        errorCode === ENUM_STATUS_CODE?.CREATE) &&
      successMessage !== "" &&
      successMessage !== undefined
    ) {
      toast.success(successMessage, {
        duration: 2000,
      });
    }else if (errorCode === ENUM_STATUS_CODE?.ACCESS_DENIED) {
      navigate("/error")
    }
    
    else if (errorCode === ENUM_STATUS_CODE?.SESSION_EXPIRED) {
      toast.warning(errorMessage || "Session expired or invalid", {
        duration: 2000,
      });
      if (currentUser?.isAuthenticated) {
        logoutHandling();
      } else return;
    } else if (errorCode == ENUM_STATUS_CODE?.RUN_TIME_ERROR) {
      toast.error(errorMessage, {
        duration: 2000,
      });

      if (currentUser?.isAuthenticated) {
        logoutHandling();
      } else return;
    }
    dispatch(updateResetError());
  }, [errorCode, successMessage]);

  return (
    <div>
      {pathname.includes("auth") ? (
        <Toaster position="top-center" richColors />
      ) : (
        <Toaster position="top-right" richColors />
      )}
      {/* <Toaster position="top-right" richColors /> */}
    </div>
  );
};

export default ErrorBoundryMain;
