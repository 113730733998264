import {
  BarChart3,
  CalendarCheck,
  FileClock,
  FileLock2,
  FileSpreadsheet,
  HeartHandshake,
  History,
  LandPlot,
  LayoutDashboard,
  ListChecks,
  LogOut,
  MenuIcon,
  Milestone,
  Settings,
  Sigma,
  User,
  Users,
  Variable,
} from "lucide-react";

export interface IMenuItem {
  name: string;
  iconName: any;
  path: string;
  options?: Array<{
    name: string;
    iconName: any;
    path: string;
  }>;
}

export const CommonMenu: IMenuItem[] = [
  {
    name: "Dashboard",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/dashboard",
  },
  {
    name: "User Management",
    iconName: (className: string) => (
      <CalendarCheck size={14} className={className} />
    ),
    path: "/user-management",
  },

  {
    name: "Invoice",
    iconName: (className: string) => (
      <FileSpreadsheet size={16} className={className} />
    ),
    path: "/invoice",
    options: [
      {
        name: "Invoice",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/invoice",
      },
    ],
  },

  {
    name: "Meter Reading",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/meter-reading",
  },

  {
    name: "Transaction",
    iconName: (className: string) => (
      <FileSpreadsheet size={16} className={className} />
    ),
    path: "/transaction",
    options: [
      {
        name: "All Transaction",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/transaction",
      },
      {
        name: "Failed Transaction",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/transaction/fail",
      },
    ],
  },
  {
    name: " PPA Contracts",
    iconName: (className: string) => (
      <CalendarCheck size={14} className={className} />
    ),
    path: "/ppa-contract",
  },
  // {
  //   name: "Time Block",
  //   iconName: (className: string) => (
  //     <LayoutDashboard size={16} className={className} />
  //   ),
  //   path: "/timeblock",
  // },
  {
    name: "Banner",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/banner",
  },
];

export const Menu: IMenuItem[] = [
  ...CommonMenu,
  {
    name: "Reports",
    iconName: (className: string) => (
      <FileSpreadsheet size={16} className={className} />
    ),
    path: "/reports",
    options: [
      {
        name: "End to End Transaction",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports",
      },
      {
        name: "Analytical Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/analytical-report",
      },
      {
        name: "Energy Injection and Drawal Analysis Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/energy-injection-drawl-report",
      },
      {
        name: "Collection Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/collection-report",
      },
      {
        name: " User Summary & Earning Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/user-summary-earning-report",
      },
      {
        name: "Report On Missing Meter Readings For Onboarded Users",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/missing-meter-readings-onboarded-users",
      },
      
    ],
  },
];

export const MenuSuperAdmin: IMenuItem[] = [
  ...CommonMenu,
  {
    name: "Configuration Parameter",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/configuration-parameter",
  },
  {
    name: "Tariff  Configuration Panel",
    iconName: (className: string) => (
      <HeartHandshake size={16} className={className} />
    ),
    path: "/tariff-configuration-panel",
    options: [
      {
        name: "Platform Charges",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/tariff-configuration-panel",
      },
      {
        name: " Customer Tariff ",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/tariff-configuration-panel/customer-tariff",
      },
    ],
  },

  {
    name: "Reports",
    iconName: (className: string) => (
      <FileSpreadsheet size={16} className={className} />
    ),
    path: "/reports",
    options: [
      {
        name: "End to End Transaction",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports",
      },
      {
        name: "Analytical Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/analytical-report",
      },
      {
        name: "Energy Injection and Drawal Analysis Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/energy-injection-drawl-report",
      },
      {
        name: "Collection Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/collection-report",
      },
      {
        name: " User Summary & Earning Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/user-summary-earning-report",
      },
      {
        name: "Report On Missing Meter Readings For Onboarded Users",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/missing-meter-readings-onboarded-users",
      },
    ],
  },
 
];

export const MenuDeveloper: IMenuItem[] = [
  ...CommonMenu,
  {
    name: "Configuration Parameter",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/configuration-parameter",
  },
  {
    name: "Time Block",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/timeblock",
  },
  {
    name: "Gross Energy",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/gross-energy",
  },
  {
    name: "Gate Closure",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/gate-closure",
  },
  {
    name: "Developers",
    iconName: (className: string) => (
      <FileSpreadsheet size={16} className={className} />
    ),
    path: "/developers",
    options: [
      {
        name: "Create Metabase",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/developers",
      },
    ],
  },
  {
    name: "Reports",
    iconName: (className: string) => (
      <FileSpreadsheet size={16} className={className} />
    ),
    path: "/reports",
    options: [
      {
        name: "End to End Transaction",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports",
      },
      {
        name: "Analytical Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/analytical-report",
      },
      {
        name: "Energy Injection and Drawal Analysis Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/energy-injection-drawl-report",
      },
      {
        name: "Collection Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/collection-report",
      },
      {
        name: " User Summary & Earning Report",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/user-summary-earning-report",
      },
      {
        name: "Report On Missing Meter Readings For Onboarded Users",
        iconName: (className: string) => (
          <CalendarCheck size={14} className={className} />
        ),
        path: "/reports/missing-meter-readings-onboarded-users",
      },
    ],
  },
];
export const otherMenuForHeading: IMenuItem[] = [
  {
    name: "Reports/Analytical Report",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/reports/analytical-report/report",
  },
  {
    name: "Tarrif/Customer Tariff  History",
    iconName: (className: string) => (
      <LayoutDashboard size={16} className={className} />
    ),
    path: "/configuration-tarif/tarrif/tarrif-history",
  },
];
