import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ILoginData {
  phone: string | undefined;
  data: any;
  consumerNo: string | undefined;
  type: string | undefined;
  // password: string | undefined,
  email: string | undefined;
  forgotEmailId: string | undefined;
  forgotData:any,
  forgotToken:string | undefined
}

const initialState: ILoginData = {
  phone: undefined,
  data: {},
  consumerNo: undefined,
  type: undefined,
  // password: undefined,
  email: undefined,
  forgotEmailId: undefined,
  forgotData:{},
  forgotToken:undefined
};

const loginSlicer = createSlice({
  name: "Login/slice",
  initialState,
  reducers: {
    updateLoginData(state, action: PayloadAction<any>) {
      state.data = action.payload;
      if (action.payload) {
        state.phone = action.payload?.phone;
      }
    },
    updatePhoneNumber(state, action: PayloadAction<any>) {
      state.phone = action.payload;
    },
    updateForgotEmailId(state, action: PayloadAction<any>) {
      state.forgotEmailId = action.payload;
    },

    updateForgotData(state, action: PayloadAction<any>) {
        state.forgotData = action.payload;
      },
      updateForgotToken(state, action: PayloadAction<any>) {
        state.forgotToken = action.payload;
      },
    // updateLogin(state, action: PayloadAction<any>) {
    //     state.phone = action.payload?.phone;
    //     state.consumerNo = action.payload?.consumerNo;
    //     // state.password = action.payload?.password
    //     state.type = action.payload?.type
    //     state.email=action?.payload?.email
    // }
  },
});

export const { updateLoginData, updatePhoneNumber, updateForgotEmailId,updateForgotData,updateForgotToken } =
  loginSlicer.actions;

export const loginReducerState = (state: RootState) => state.login;
export default loginSlicer.reducer;
